.footer {
  &__content {
    padding-top: $spacer*1.8 0;

    a {
      color: #333;
      font-size: 24px;
    }

    .linkedin-link {
      color: #0077B5;
    }

    .dribbble-link {
      color: #C32361;
    }
  }

  &__button {
    padding: $spacer 0;
    a {
      margin: $spacer 0;
    }
  }
  &__bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: $spacer;
  }
}
