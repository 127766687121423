.intro-section {
	padding: $spacer 0;
	padding-top: $spacer * 3;
	@include media-breakpoint-up(sm) {
		padding: $spacer * 5 0;
	}

	.me-intro {
		&__image {
			img {
				width: 20%;
				@include media-breakpoint-up(sm) {
					width: 100%;
				}
			}
		}

		&__text {
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding-top: $spacer;
			}
			@include media-breakpoint-up(sm) {
				text-align: left;

				br {
					display: none;
				}
			}

			h1 {
				font-size: 30px;
			}
		}

		.contact-details {
			margin-top: $spacer * 3;

			h2 {
				font-size: 24px;
			}
		}
	}

	.about-me {
		padding-top: $spacer * 1.5;

		p {
			margin-bottom: auto;

			&:last-child {
				margin-top: $spacer;
			}
		}
	}
}

.tech-stack {
	ul {
		list-style-type: none;
		padding: 0;

		li {
			margin-left: 0;
		}
	}

	.key {
		text-align: center;
		@include media-breakpoint-up(sm) {
			text-align: left;
		}
	}

	.fa-star {
		&--legendary {
			color: #37c976;
		}

		&--good {
			color: #bbde66;
		}

		&--learning {
			color: #daa85c;
		}
	}
}

.feature {
	border-radius: 4px;
	a {
		text-decoration: underline;
	}
}

.home-pf {
	padding-bottom: $spacer * 5;
	padding-top: $spacer * 2;

	&__heading {
		h3 {
			font-size: 22px;
			padding-bottom: $spacer;
		}
	}

	&__cards {
		.work-card {
			background: #fff;
			border: 1px solid $green;
			border-radius: 3px;
			margin-bottom: $spacer;
			@include media-breakpoint-up(md) {
				transition: 0.2s;

				&:hover {
					transform: scale(1.05);
				}
			}

			a {
				color: #616161;
			}

			&__image {
				overflow: hidden;

				img {
					width: 100%;
				}
			}

			&__text {
				padding: $spacer 0;

				h4,
				p {
					text-align: center;
				}

				h4 {
					font-size: 22px;
				}

				p {
					font-size: 16px;
					color: #979797;
				}
			}
		}
	}
}

.home-news {
	padding: $spacer * 5 0;

	&__heading {
		h3 {
			font-size: 22px;
			padding-bottom: $spacer;
		}
	}

	.news-card {
		padding-bottom: $spacer * 1.5;

		img {
			padding-bottom: $spacer * 0.5;
		}

		a {
			color: #333;
		}

		p {
			font-size: 14px;
		}
	}
}

.react {
	background: #17a2b8; /* fallback for old browsers */
	background: -webkit-linear-gradient(left, $green, #17a2b8);
	background: -o-linear-gradient(left, $green, #17a2b8);
	background: linear-gradient(
		to right,
		$green,
		#17a2b8
	); /* Chrome 10-25, Safari 5.1-6 */ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff;
	padding: $spacer * 5 0;

	h3 {
		font-size: 30px;
	}

	a {
		color: #fff;
		text-decoration: underline;
	}

	.react-card {
		text-align: center;
		color: #fff;
		margin-bottom: $spacer * 3;

		a {
			color: #fff;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		img {
			width: 50%;

			@include media-breakpoint-up(md) {
				width: 75%;
			}
		}
	}
}
