nav.navbar {
  background: #fff;

  .navbar-brand {
    color: #233;
    margin-right: 0;
    font-family: "Suravaram";
    font-size: 30px;
  }
  .navbar-toggler {
    background:transparent;
    color:#333;
  }
  ul {
    li {
      @include media-breakpoint-up(sm) {
        margin-left : $spacer*.5;
        margin-right : $spacer*.5;
      }
      a.nav-link {
        color: $text;
      }
    }
  }
}
