.cv {
	padding: $spacer 0;

	@include media-breakpoint-up(md) {
		padding: $spacer * 3 0;
	}

	.coder {
		padding: calc($spacer / 2);
		background: #1e272b;
		border-radius: 2px !important;

		pre {
			white-space: pre-wrap;
			width: auto;
			display: inline;
			margin-bottom: $spacer * 3;

			code {
				padding-left: 7px;
				color: #fff;

				@include media-breakpoint-up(md) {
					padding: $spacer;
				}

				.pink {
					color: #c26ea3;
				}

				.blue {
					color: #1aaed5;
				}

				.green {
					color: #e7ad2d;
				}
			}
		}
	}

	&__text {
		margin-top: $spacer * 3;

		hr {
			background-color: #e5e5e5;
			border-color: #e5e5e5;
			margin-left: 0;
		}

		h1,
		h2 {
			font-size: 24px;
		}

		.first {
			margin-top: $spacer * 2;
		}

		h3 {
			font-size: 20px;
			line-height: 20px * 1.4;

			.location,
			.position {
				font-weight: 400;
			}

			.grad {
				font-weight: 400;
				font-size: 18px;
			}

			.sites {
				font-weight: 300;
				font-size: 16px;
			}
		}

		&--last {
			margin-bottom: $spacer * 3;
		}
	}
}
